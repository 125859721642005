// ** Initial State
const initialState = {
  allData: [],
  reminders:[],
  enquiries_requests:{enquiries_requests:[],unseen:0},
  new_enquiries_requests:{enquiries_requests:[],unseen:0},

  hotels_specialists:[],
  notifications:{notifications:[],unseen:0},
  new_notifications:{notifications:[],unseen:0},

  total: 1,
  params: {},
  selectedRecord: null
}

const record = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_CONFIGS':
      return { ...state, allData: action.data }
    case 'GET_DATA_CONFIGS_REMINDERS':
      return {
        ...state,
        reminders: action.data,
        total: action.totalPages,
        params: action.params
      }
      case 'GET_DATA_CONFIGS_ENQUIRIES_REQUESTS':
        return {
          ...state,
          enquiries_requests: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_DATA_CONFIGS_NEW_ENQUIRIES_REQUESTS':
        return {
          ...state,
          new_enquiries_requests: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_DATA_CONFIGS_HOTEL_SPECIALISTS':
        return {
          ...state,
          hotels_specialists: action.data,
          total: action.totalPages,
          params: action.params
        }
    case 'GET_DATA_CONFIGS_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA_CONFIGS_NEW_NOTIFICATIONS':
      return {
        ...state,
        new_notifications: action.data,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}
export default record
