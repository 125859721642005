// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import dashboard_widgets from '@src/views/dashboard/store/reducer'
import users from '@src/views/settings/users/store/reducer'
import roles from '@src/views/settings/roles/store/reducer'
import chart_of_accounts from '../../views/accounts/chart_of_accounts/store/reducer'
import general_journal from '../../views/accounts/general_journal/store/reducer'
import expenses_heads from '../../views/accounts/expenses_heads/store/reducer'
import expenses from '../../views/accounts/expenses/store/reducer'
import accounts from '../../views/accounts/accounts/store/reducer'
import general_receipts from '../../views/accounts/receivable/general_receipts/store/reducer'
import hotel_refunds from '../../views/accounts/hotels/hotel_refunds/store/reducer'
import hotel_request_vouchers from '../../views/accounts/hotels/hotel_requests/store/reducer'
import hotel_payments from '../../views/accounts/hotels/hotel_payments/store/reducer'
import hotel_vouchers from '../../views/accounts/hotels/hotel_vouchers/store/reducer'


import transport_request_vouchers from '../../views/accounts/transports/transport_requests/store/reducer'
import transport_payments from '../../views/accounts/transports/transport_payments/store/reducer'

import branches from '../../views/configs/branches/store/reducer'
import account_settings from '../../views/settings/account_settings/store/reducer'
import logs from '../../views/common/logs/store/reducer'
import companies from '../../views/people_management/companies/store/reducer'
import customers from '../../views/people_management/customers/store/reducer'
import suppliers from '../../views/people_management/suppliers/store/reducer'
import room_types from '../../views/hotels_management/room_types/store/reducer'
import hotels from '../../views/hotels_management/hotels/store/reducer'
import transport_types from '../../views/transportation/transport_types/store/reducer'

import service_enquiries from '../../views/booking/service_enquiries/store/reducer'
import hotel_requests from '../../views/hotels_management/hotel_requests/store/reducer'
import transport_requests from '../../views/transportation/transport_requests/store/reducer'

import hotel_confirmations from '../../views/booking/hotel_confirmations/store/reducer'
import transport_confirmations from '../../views/booking/transport_confirmations/store/reducer'
import custom_hotel_vouchers from '../../views/booking/custom_hotel_vouchers/store/reducer'
import old_azar_vouchers from '../../views/booking/old_azar_vouchers/store/reducer'
import old_transport_vouchers from '../../views/booking/old_transport_vouchers/store/reducer'

import countries from '../../views/configs/locations/countries/store/reducer'
import cities from '../../views/configs/locations/cities/store/reducer'
import airports from '../../views/configs/locations/airports/store/reducer'

import company_contacts from '../../views/configs/company/contacts/store/reducer'
import company_policies from '../../views/configs/company/policies/store/reducer'

import configs from '../../@core/layouts/store/reducer'
import notifications from '../../views/configs/notifications/store/reducer'
import enquiries_requests from '../../views/configs/enquiries_requests/store/reducer'
import remainders from '../../views/configs/remainders/store/reducer'
import currencies from '../../views/configs/currencies/store/reducer'
import extra_services from '../../views/configs/extra_services/store/reducer'



const rootReducer = combineReducers({
  auth,
  dashboard_widgets,
  users,
  roles,
  navbar,
  layout,
  companies,
  customers,
  suppliers,
  room_types,
  hotels,
  transport_types,
  transport_requests,
  service_enquiries,
  hotel_requests,
  hotel_confirmations,
  transport_confirmations,
  custom_hotel_vouchers,
  old_azar_vouchers,
  old_transport_vouchers,
  countries,
  cities,
  airports,
  company_contacts,
  company_policies,
  chart_of_accounts,
  general_journal,
  expenses_heads,
  expenses,
  accounts,
  hotel_payments,
  hotel_vouchers,
  hotel_request_vouchers,
  general_receipts,
  hotel_refunds,
  branches,
  account_settings,
  logs,
  configs,
  notifications,
  enquiries_requests,
  remainders,
  currencies,
  extra_services,
  transport_request_vouchers,
  transport_payments,
})

export default rootReducer
